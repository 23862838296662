import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/SEO"

const FAQ = () => {
  return (
    <Layout>
      <SEO title="FAQs" />
      <div>This is the FAQ page</div>
    </Layout>
  )
}

export default FAQ
